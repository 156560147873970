<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '通过通知' : '拒绝通知'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="noticeForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="发送对象">
        {{ staffNameList.toString() }}
      </el-form-item>
      <el-form-item label="发送内容" class="send-content">
        <div class="textarea-wrap" :class="{ focus: isFocus }">
          <div class="textarea-title">{{ noticeTitle }}</div>
          <el-input
            v-model="noticeForm.content"
            type="textarea"
            :rows="2"
            placeholder="请输入通知内容"
            :maxlength="300"
            @focus="startFocus"
            @blur="endFocus"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="noticeForm.isSendMessage" style="margin-left: -25px;">是否发送短信通知</el-checkbox>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading" class="button">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import jobApi from '@api/job'
export default {
  name: 'StaffApplyNoticeDialog',
  data() {
    return {
      visible: false,
      mode: false,
      loading: false,
      noticeForm: {
        content: '',
        isSendMessage: false
      },
      ruleValidate: {},
      staffNameList: [],
      noticeTitle: '',
      isFocus: false
    }
  },
  methods: {
    open(mode, data) {
      this.visible = true
      this.mode = mode
      if (mode) {
        this.noticeTitle = `恭喜你，你已通过${data.jobName}的初筛`
      } else {
        this.noticeTitle = `很抱歉的通知你，你没有通过初筛`
      }
      this.staffNameList = data.userName
      this.jobResumeIds = [data.jobResumeId]
    },
    batchOpen(mode, list) {
      // 批量操作时打开弹窗方法
      this.visible = true
      this.mode = mode
      this.staffNameList = list.map(item => {
        return item.userName
      })
      this.jobResumeIds = list.map(item => {
        return item.jobResumeId
      })
      if (mode) {
        const titleList = list.map(t => {
          return t.jobName
        })
        this.noticeTitle = `恭喜你，你已通过${[...new Set(titleList)].toString()}的初筛`
      } else {
        this.noticeTitle = `很抱歉的通知你，你没有通过初筛`
      }
    },
    close(done) {
      done()
    },
    closed() {
      this.noticeForm = {
        content: '',
        isSendMessage: false
      }
      this.loading = false
    },
    submit() {
      let payload = {
        isSendMessage: this.noticeForm.isSendMessage,
        jobResumeIds: this.jobResumeIds,
        content: this.noticeForm.content
      }
      if (this.mode) {
        jobApi
          .sendPassMessage(payload)
          .then(res => {
            if (res.res === true) {
              this.$message.success('通过通知已发送')
              this.$emit('on-ok')
              this.visible = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        jobApi
          .sendRefuseMessage(payload)
          .then(res => {
            if (res.res === true) {
              this.$message.success('拒绝通知已发送')
              this.$emit('on-ok')
              this.visible = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    startFocus() {
      this.isFocus = true
    },
    endFocus() {
      this.isFocus = false
    }
  }
}
</script>

<style lang="less" scoped>
.textarea-wrap {
  width: 352px;
  height: 100%;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover {
    border-color: #c0c4cc;
  }
  &.focus {
    border-color: #409eff;
  }
}
.textarea-title {
  padding: 8px 11px 0;
  line-height: 1.5;
}
::v-deep .el-textarea__inner {
  padding-top: 0;
  border: none;
}
.send-content {
  margin-bottom: 20px;
}
</style>
