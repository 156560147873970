import config from '../config/config'
import { axios } from './index'

const jobUrl = config.baseUrl + '/user/api/v1/job'

export default {
  //获取企业下未查看简历、已收到简历数量
  getResumeNum() {
    return axios.get(`${jobUrl}/get/resume/num`)
  },
  //获取企业下招聘职位、可发布职位数量
  getRecruitmentNum() {
    return axios.get(`${jobUrl}/get/recruitment/num`)
  },
  //获取企业下申请最多职位及数量、浏览最多职位及数量
  getMostJob() {
    return axios.get(`${jobUrl}/get/num`)
  },
  //分页获取企业下职位信息
  getJobList(payload, page, size) {
    return axios.post(`${jobUrl}/get?page=${page}&size=${size}`, payload)
  },
  //获取职位详情
  getJobDetail(id) {
    return axios.get(`${jobUrl}/get/detail?jobId=${id}`)
  },
  //分页获取企业下职位投递人信息
  getJobResumeSender(id, page, size) {
    return axios.get(`${jobUrl}/get/resume-sender?jobId=${id}&page=${page}&size=${size}`)
  },
  //分页获取企业下投递人信息
  searchResumeSenderByJob(page, size, payload) {
    return axios.post(`${jobUrl}/search/resume-sender/by-job?page=${page}&size=${size}&name=${payload.userName}`, payload)
  },
  //分页搜索企业下职位投递人信息
  searchResumeSender(page, size, payload) {
    return axios.post(`${jobUrl}/search/resume-sender?page=${page}&size=${size}&name=${payload.name}`, payload)
  },
  //分页搜索企业下职位投递人信息(根据申请时间排序)
  searchLatestResumeSender(page, size, payload) {
    return axios.post(`${jobUrl}/search/resume-sender/latest?page=${page}&size=${size}&name=${payload.name}`, payload)
  },
  //删除职位
  deleteJobDetail(id) {
    return axios.post(`${jobUrl}/delete?jobId=${id}`)
  },
  //获取职位类型
  getJobType() {
    return axios.get(`${jobUrl}/get/job-type`)
  },
  //获取所有职位名称
  getAllJobName() {
    return axios.get(`${jobUrl}/get/all/name`)
  },
  //获取工作类型/性质
  getWorkType() {
    return axios.get(`${jobUrl}/get/work-type`)
  },
  //获取教育类型
  getEducationType() {
    return axios.get(`${jobUrl}/get/education-type`)
  },
  //获取工作经验类型
  getExperienceType() {
    return axios.get(`${jobUrl}/get/experience-type`)
  },
  //获取教育类型
  getEducationTypeSearch() {
    return axios.get(`${jobUrl}/get/education-type/search`)
  },
  //获取工作经验类型
  getExperienceTypeSearch() {
    return axios.get(`${jobUrl}/get/experience-type/search`)
  },
  //获取笔试等级
  getScoreRankType() {
    return axios.get(`${jobUrl}/get/score-rank-type`)
  },
  //更新职位
  updateJob(payload) {
    return axios.post(`${jobUrl}/update`, payload)
  },
  //分页获取招聘信息
  getRecruitment(page, size) {
    return axios.get(`${jobUrl}/get/recruit?page=${page}&size=${size}`)
  },
  //更新招聘信息
  updateRecruitment(payload) {
    return axios.post(`${jobUrl}/update/recruit`, payload)
  },
  //删除招聘信息
  deleteRecruitment(payload) {
    return axios.post(`${jobUrl}/delete/recruit?jobRecruitId=${payload}`)
  },
  //获取招聘类型
  getJobRecruitType() {
    return axios.get(`${jobUrl}/get/job-recruit-type`)
  },
  //获取所有校招名称
  getAllSchoolRecruitmentName() {
    return axios.get(`${jobUrl}/get/all/name/recruit/school`)
  },
  //邀请考试
  inviteExam(payload) {
    return axios.post(`${jobUrl}/invite/exam`, payload)
  },
  //查看简历信息
  getResumeDetail(jobResumeId) {
    return axios.get(`${jobUrl}/view/resume/detail?jobResumeId=${jobResumeId}`)
  },
  //简历不合适
  verifyResume(jobResumeId) {
    return axios.post(`${jobUrl}/verify/resume?jobResumeId=${jobResumeId}`)
  },
  //查看简历
  viewResume(jobResumeId) {
    return axios.post(`${jobUrl}/view/resume?jobResumeId=${jobResumeId}`)
  },
  //邀请面试
  inviteInterview(payload) {
    return axios.post(`${jobUrl}/invite/interview`, payload)
  },
  //取消面试
  cancelInterview(payload) {
    return axios.post(`${jobUrl}/cancel/interview?jobResumeId=${payload}`)
  },
  //开始面试
  startInterview(payload) {
    return axios.post(`${jobUrl}/start/interview?jobResumeId=${payload}`)
  },
  //上架
  online(jobId) {
    return axios.post(`${jobUrl}/online?jobId=${jobId}`)
  },
  //下架
  offline(jobId) {
    return axios.post(`${jobUrl}/offline?jobId=${jobId}`)
  },
  //获取职位状态
  getJobStatus() {
    return axios.get(`${jobUrl}/get/job-status`)
  },
  //获取上架时长
  getExpireTime() {
    return axios.get(`${jobUrl}/get/expire-time`)
  },
  //获取院校名称
  getSchoolType() {
    return axios.get(`${jobUrl}/get/school-type`)
  },
  //获取性别
  getGenderType() {
    return axios.get(`${jobUrl}/get/gender-type`)
  },
  //获取申请时间
  getApplyTimeType() {
    return axios.get(`${jobUrl}/get/apply-time-type`)
  },
  //获取申请状态
  getApplyStatus() {
    return axios.get(`${jobUrl}/get/resume-sender-status`)
  },
  //招聘通过
  sendPassMessage(payload) {
    return axios.post(`${jobUrl}/send/pass/message`, payload)
  },
  //招聘拒绝
  sendRefuseMessage(payload) {
    return axios.post(`${jobUrl}/send/refuse/message`, payload)
  },
  exportResumeSender(payload) {
    return axios.post(`${jobUrl}/export/resume-sender`, payload, {
      responseType: 'blob'
    })
  },
  //获取企业下申请职位、参加测试人数、通过测试人数
  getTalentNum() {
    return axios.get(`${jobUrl}/get/talent/num`)
  },
  //获取企业下职位前12周，前12月 申请人数、参加测试人数、通过测试人数
  getJobDateTalentInfo(jobId, type) {
    return axios.get(`${jobUrl}/get/job/date/talent/num/info?jobId=${jobId}&type=${type}`)
  },
  //获取企业下职位人数数据
  getJobTalentInfo(jobId) {
    return axios.get(`${jobUrl}/get/job/talent/num/info?jobId=${jobId}`)
  },
  //获取企业日期下职位申请,测试,通过情况
  getJobTalentInfoByDate(date) {
    return axios.get(`${jobUrl}/get/job/talent/info/by-date?date=${date}`)
  }
}
