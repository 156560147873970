<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item to="/manage/recruitment/job">职位管理</el-breadcrumb-item>
        <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div class="yt-content" ref="header" style="height: auto; padding: 20px 0">
        <div class="top-wrapper">
          <div class="top">
            <h1>{{ jobDetail.jobName }}</h1>
            <h1 style="margin: 0 20px; color: #448BFF">{{ salary }}</h1>
            <ul style="display: flex">
              <li>{{ jobDetail.jobTypeName }}</li>
              <li>{{ jobDetail.workTypeName }}</li>
            </ul>
          </div>
          <div class="edit" @click="toUpdate">
            编辑
            <YTIcon :href="'#icon-shuangjiantou'" @click="toUpdate" style="margin-left: 3px" />
          </div>
        </div>
        <div class="requirement-wrapper">
          <ul class="requirement">
            <li class="has-border">
              <el-tooltip
                effect="dark"
                :content="jobDetail.workPlace.toString()"
                :disabled="jobDetail.workPlace.toString().length < 10"
                placement="top-start"
              >
                <p class="textEllipsis">{{ jobDetail.workPlace.toString() }}</p>
              </el-tooltip>
            </li>
            <li class="has-border">{{ jobDetail.experienceName }}</li>
            <li>{{ jobDetail.educationName }}</li>
          </ul>
          <div style="opacity: 0.6">发布时间：{{ $formatTime(jobDetail.createTime, 'yyyy-MM-dd') }}</div>
        </div>
        <el-divider />
        <div class="block has-border">
          <p class="title">职位描述</p>
          <div class="text">{{ jobDetail.description }}</div>
        </div>
        <el-divider />
        <div class="block has-border" style="padding-bottom: 0">
          <p class="title">职位要求</p>
          <div class="text">{{ jobDetail.requirements }}</div>
        </div>
      </div>
      <div class="yt-header">
        <div class="yt-header-toolbar" style="flex-shrink: 0">
          <el-dropdown trigger="click" placement="bottom-start" @command="selectEducation">
            <p>学历：{{ activeEducation }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item v-for="(item, key) in educationData" :key="key" :command="item.code" :class="{ active: item.code === education }">
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" placement="bottom-start" @command="selectJobId">
            <p>年龄：{{ activeAge }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-input-number v-model="minAge" :min="0" :max="999" :precision="0" :controls="false" />
              <i>-</i>
              <el-input-number v-model="maxAge" :min="0" :max="999" :precision="0" :controls="false" />
              <div class="footer">
                <el-button style="margin-right: 10px;" @click="clearAge">清空</el-button>
                <el-button type="primary" style="margin-right: 10px;" @click="reSearchJob">确定</el-button>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" placement="bottom-start" @command="selectExperience">
            <p>工作年限：{{ activeExperience }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item v-for="(item, key) in experienceData" :key="key" :command="item.code" :class="{ active: item.code === experience }">
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" placement="bottom-start" @command="selectStatus">
            <p>申请状态：{{ applyStatus.label ? applyStatus.label[status] : '' }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item v-for="(item, key) in applyStatus.list" :key="key" :command="item.code" :class="{ active: item.code === status }">
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" placement="bottom-start" @command="selectScoreRank">
            <p>笔试：{{ scoreRankOptions.label[scoreRank] }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item
                v-for="(item, key) in scoreRankOptions.list"
                :key="key"
                :command="item.code"
                :class="{ active: item.code === scoreRank }"
              >
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <p @click="showMoreScreen">更多筛选</p>
        </div>
        <el-input
          v-model="keyword"
          placeholder="根据人员名称搜索"
          prefix-icon="el-icon-search"
          class="yt-search"
          @keydown.enter.native="reSearchJob"
        />
      </div>
      <div class="yt-content" style="height: auto; position: relative">
        <el-table
          ref="staffTable"
          :data="senderList"
          v-loading="senderLoading"
          element-loading-text="加载中"
          class="yt-table"
          row-key="jobResumeId"
          @select-all="handleSelection"
          @select="handleSelection"
        >
          <el-table-column type="selection" width="31" />
          <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
          <el-table-column label="性别" prop="gender" show-overflow-tooltip />
          <el-table-column label="学历" prop="education" show-overflow-tooltip />
          <el-table-column label="学校" prop="school" show-overflow-tooltip />
          <el-table-column label="年龄" prop="age" show-overflow-tooltip />
          <el-table-column label="工作年限" prop="experience" show-overflow-tooltip />
          <el-table-column label="笔试" prop="examRank" show-overflow-tooltip />
          <el-table-column label="申请时间" show-overflow-tooltip>
            <template slot-scope="scope"> {{ $formatTime(scope.row.createTime, 'yyyy-MM-dd hh:mm') }} </template>
          </el-table-column>
          <el-table-column label="申请状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="dot" :style="{ backgroundColor: colorEnum[scope.row.status] }"></span>
              <span :style="{ color: colorEnum[scope.row.status] }">{{ statusEnum[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <span class="tool-button" @click="pass(scope.row)" :class="{ stop: scope.row.status !== 0 }">通过</span>
              <span class="tool-button" @click="refuse(scope.row)" :class="{ stop: scope.row.status !== 0 }">拒绝</span>
              <el-dropdown placement="bottom-start" @command="handleCommand($event, scope.row)">
                <span class="tool-button">更多</span>
                <el-dropdown-menu class="table-dropdown" slot="dropdown">
                  <el-dropdown-item command="detail">查看简历</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.examScore !== null" command="examResult">查看测评</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!senderLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
        <transition name="el-zoom-in-bottom">
          <div v-show="operationVisible" class="bottom-drawer">
            <el-button type="primary" size="small" @click="batchPass">通过</el-button>
            <el-button size="small" @click="batchRefuse">拒绝</el-button>
            <el-button size="small" @click="exportResume">导出</el-button>
            <el-button size="small" :loading="downloadLoading" @click="downloadResume">下载简历</el-button>
          </div>
        </transition>
      </div>
      <transition name="el-fade-in">
        <div v-show="screenVisible" class="more-filter">
          <div class="back" @click="closeMoreScreen"></div>
          <div class="content">
            <div class="top">
              <p>更多筛选</p>
              <i slot="suffix" class="el-icon-close" @click="closeMoreScreen"></i>
            </div>
            <div class="filter-block">
              <p>院校</p>
              <ul>
                <li v-for="(item, key) in schoolOptions" :key="key" :class="{ active: item.code === school }" @click="selectSchool(item)">
                  {{ item.value }}
                </li>
              </ul>
            </div>
            <div class="filter-block">
              <p>性别</p>
              <ul>
                <li v-for="(item, key) in genderOptions" :key="key" :class="{ active: item.code === gender }" @click="selectGender(item)">
                  {{ item.value }}
                </li>
              </ul>
            </div>
            <div class="filter-block">
              <p>申请时间</p>
              <ul>
                <li v-for="(item, key) in applyTimeOptions" :key="key" :class="{ active: item.code === applyTime }" @click="selectTime(item)">
                  {{ item.value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <StaffApplyNoticeDialog ref="staffNoticeDialog" @on-ok="sendMessage" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import jobApi from '@api/job'
import ossApi from '@api/oss'
import StaffApplyNoticeDialog from '@components/common/dialog/StaffApplyNoticeDialog'

export default {
  name: 'JobDetail',
  components: { YTIcon, StaffApplyNoticeDialog },
  data() {
    return {
      jobId: '',
      status: '',
      education: '',
      experience: '',
      school: -1,
      gender: -1,
      applyTime: -1,
      minAge: undefined,
      maxAge: undefined,
      minScore: undefined,
      maxScore: undefined,
      scoreRank: -1,
      keyword: '',
      educationData: [],
      experienceData: [], //工作年限
      experienceEnum: [
        { maxExperience: '', minExperience: '' },
        { maxExperience: 1, minExperience: 1 },
        { maxExperience: 2, minExperience: 2 },
        { maxExperience: 3, minExperience: 3 },
        { maxExperience: 5, minExperience: 3 },
        { maxExperience: '', minExperience: 5 }
      ], //工作经验
      applyStatus: [], //申请状态
      jobDetail: {
        workPlace: []
      },
      senderList: [],
      senderLoading: false,
      staffSelections: [], //选中申请人
      pageNum: 0,
      pageSize: 10,
      total: 0,
      statusEnum: ['未通知', '已拒绝', '已通过'],
      colorEnum: ['#FF5050', '#999999', '#448BFF'],
      screenVisible: false, //更多筛选
      schoolOptions: [],
      genderOptions: [],
      applyTimeOptions: [],
      scoreRankOptions: {
        label: {},
        list: []
      },
      operationVisible: false,
      downloadLoading: false
    }
  },
  computed: {
    salary() {
      if (this.jobDetail.minSalary === null && this.jobDetail.maxSalary === null) {
        return '面议'
      } else {
        let isConvert = this.jobDetail.minSalary % 1000 === 0 && this.jobDetail.maxSalary % 1000 === 0
        let min = ''
        if (this.jobDetail.minSalary) {
          min = isConvert ? this.jobDetail.minSalary / 1000 : this.jobDetail.minSalary
        }
        let max = ''
        if (this.jobDetail.maxSalary) {
          max = isConvert ? this.jobDetail.maxSalary / 1000 : this.jobDetail.maxSalary
        }
        return `${min}${this.jobDetail.minSalary && this.jobDetail.maxSalary ? '-' : ''}${max}${isConvert ? 'K' : ''}`
      }
    },
    activeEducation() {
      if (this.educationData && this.educationData.length > 0) {
        return this.educationData.find(item => item.code === this.education).value
      } else {
        return ''
      }
    },
    activeAge() {
      if (this.minAge === undefined && this.maxAge === undefined) {
        return '不限'
      }
      return `${this.minAge === undefined ? '不限' : this.minAge}-${this.maxAge === undefined ? '不限' : this.maxAge}`
    },
    activeExperience() {
      if (this.experienceData && this.experienceData.length > 0) {
        return this.experienceData.find(item => item.code === this.experience).value
      } else {
        return ''
      }
    }
  },
  created() {
    this.getEducationData()
    this.getSchoolType()
    this.getGenderType()
    this.getApplyTimeType()
    this.getExperienceData()
    this.getApplyStatus()
    this.getScoreRankType()
  },
  mounted() {
    this.jobId = this.$handleParams('JobDetailData').jobId
    this.getJobDetail()
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = to.name === 'jobDetail' || to.name === 'jobRelease'
    next()
  },
  methods: {
    getEducationData() {
      //获取学历
      jobApi.getEducationTypeSearch().then(res => {
        this.educationData = res.res
        this.education = res.res[0].code
      })
    },
    getSchoolType() {
      //获取院校名称
      jobApi.getSchoolType().then(res => {
        this.schoolOptions = res.res
      })
    },
    getGenderType() {
      //获取性别
      jobApi.getGenderType().then(res => {
        this.genderOptions = res.res
      })
    },
    getApplyTimeType() {
      //获取申请时间
      jobApi.getApplyTimeType().then(res => {
        this.applyTimeOptions = res.res
      })
    },
    getExperienceData() {
      //获取工作经验
      jobApi.getExperienceTypeSearch().then(res => {
        this.experienceData = res.res
        this.experience = res.res[0].code
      })
    },
    getApplyStatus() {
      //获取申请状态
      jobApi.getApplyStatus().then(res => {
        let map = {}
        res.res.forEach(item => {
          map[item.code] = item.value
        })
        this.applyStatus = {
          list: res.res,
          label: map
        }
        this.status = res.res[0].code
      })
    },
    getJobDetail() {
      //获取职位详情
      jobApi
        .getJobDetail(this.jobId)
        .then(res => {
          this.jobDetail = res.res
        })
        .finally(() => {
          this.searchResumeByJob()
        })
    },
    getScoreRankType() {
      //获取笔试等级
      jobApi.getScoreRankType().then(res => {
        let map = {}
        res.res.forEach(item => {
          map[item.code] = item.value
        })
        this.scoreRankOptions = {
          list: res.res,
          label: map
        }
        this.scoreRank = res.res[0].code
      })
    },
    searchResumeByJob() {
      //分页搜索企业下职位投递人信息
      this.senderLoading = true
      let payload = {
        userName: this.keyword,
        education: this.education,
        ...this.experienceEnum[this.experience],
        applyTime: this.applyTime,
        gender: this.gender,
        school: this.school,
        jobId: [this.jobId],
        minAge: this.minAge,
        maxAge: this.maxAge,
        minScore: this.minScore,
        maxScore: this.maxScore,
        status: this.status,
        scoreRank: this.scoreRank
      }
      jobApi
        .searchResumeSenderByJob(this.pageNum, this.pageSize, payload)
        .then(res => {
          this.senderList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.senderLoading = false
        })
    },
    selectEducation(data) {
      // 学历筛选
      this.education = data
      this.reSearchJob()
    },
    selectStatus(data) {
      //申请状态筛选
      this.status = data
      this.reSearchJob()
    },
    selectJobId(data) {
      // 职位名称筛选
      this.jobId = data
      this.reSearchJob()
    },
    selectExperience(data) {
      //工作年限筛选
      this.experience = data
      this.reSearchJob()
    },
    selectSchool(data) {
      //学校筛选
      this.school = data.code
      this.reSearchJob()
    },
    selectGender(data) {
      //性别筛选
      this.gender = data.code
      this.reSearchJob()
    },
    selectTime(data) {
      //申请时间筛选
      this.applyTime = data.code
      this.reSearchJob()
    },
    selectScoreRank(data) {
      //笔试等级筛选
      this.scoreRank = data
      this.reSearchJob()
    },
    clearAge() {
      //重置年龄
      this.minAge = undefined
      this.maxAge = undefined
    },
    clearScore() {
      //重置分数
      this.minScore = undefined
      this.maxScore = undefined
    },
    reSearchJob() {
      this.pageNum = 0
      this.searchResumeByJob()
    },
    toUpdate() {
      //编辑职位
      this.$router.push({
        name: 'jobRelease',
        params: {
          detail: this.$deepCopy(this.jobDetail),
          mode: '0',
          fromRoute: 'jobDetail'
        }
      })
    },
    batchPass() {
      //批量通过
      const staffSelectionList = this.staffSelections
        .filter(item => {
          return item.status === 0
        })
        .map(item => {
          item.jobName = this.jobDetail.jobName
          return item
        })
      if (staffSelectionList.length > 0) {
        this.$refs.staffNoticeDialog.batchOpen(true, staffSelectionList)
      } else {
        this.$message.warning('存在已通知用户, 请勿重复操作')
      }
    },
    pass(data) {
      //通过
      if (data.status === 0) {
        this.$refs.staffNoticeDialog.open(true, { ...data, jobName: this.jobDetail.jobName })
      }
    },
    refuse(data) {
      //拒绝
      if (data.status === 0) {
        this.$refs.staffNoticeDialog.open(false, { ...data, jobName: this.jobDetail.jobName })
      }
    },
    batchRefuse() {
      //批量拒绝
      const staffSelectionList = this.staffSelections.filter(item => {
        return item.status === 0
      })
      if (staffSelectionList.length > 0) {
        this.$refs.staffNoticeDialog.batchOpen(false, staffSelectionList)
      } else {
        this.$message.warning('存在已通知用户, 请勿重复操作')
      }
    },
    exportResume() {
      let payload = {
        jobResumeIds: this.staffSelections.map(item => {
          return item.jobResumeId
        })
      }
      jobApi.exportResumeSender(payload).then(res => {
        this.$downloadFlow(res, '人才信息', 'xls')
      })
    },
    downloadResume() {
      let payload = {
        jobResumeIds: this.staffSelections.map(item => {
          return item.jobResumeId
        })
      }
      this.downloadLoading = true
      ossApi
        .downloadResumeFile(payload)
        .then(res => {
          if (res.type === 'application/json') {
            let reader = new FileReader()
            reader.readAsText(res, 'utf-8')
            reader.onload = e => {
              const res = JSON.parse(reader.result)
              this.$message.error(res.res)
            }
          } else {
            this.$downloadFlow(res, '简历', 'zip')
          }
        })
        .finally(() => {
          this.downloadLoading = false
        })
    },
    sendMessage() {
      //发送通知消息
      this.operationVisible = false
      this.searchResumeByJob()
    },
    handleCommand(command, row) {
      if (command === 'detail') {
        this.checkResume(row)
      } else if (command === 'examResult') {
        this.jumpToResult(row)
      }
    },
    checkResume(row) {
      //查看简历
      //设置申请已查看
      if (!row.isView) {
        jobApi.viewResume(row.jobResumeId).finally(() => {
          this.jumpToResume(row)
        })
      } else {
        this.jumpToResume(row)
      }
    },
    jumpToResume(row) {
      this.$router.push({
        name: 'jobResume',
        params: {
          id: row.jobResumeId
        }
      })
    },
    jumpToResult(row) {
      this.$router.push({
        name: 'recruitmentExamResult',
        params: {
          id: row.jobResumeId,
          fromRoute: 'jobDetail'
        }
      })
    },
    handleSelection(selection) {
      //处理表格选中
      if (selection.length === 0) {
        this.operationVisible = false
        this.staffSelections = []
      } else {
        this.operationVisible = true
        this.staffSelections = selection
      }
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchResumeByJob()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchResumeByJob()
    },
    showMoreScreen() {
      this.screenVisible = true
    },
    closeMoreScreen() {
      this.screenVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/recruitmentDropdownMenu';
@import '~@/theme/tableDropdownMenu';
@import '~@/theme/moreFilterDrawer';
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: 100%;
  padding: 10px;
  .yt-header {
    height: 52px;
    margin: 10px 0;
    padding: 0 20px;
    align-items: center;
    p {
      font-size: @medium;
      margin-right: 20px;
      flex-shrink: 0;
      cursor: pointer;
      color: #1f1f1f;
    }
    .yt-search {
      max-width: 268px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 30px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
      }
    }
  }
}
.el-input-number {
  width: 56px !important;
  margin: 10px 2px 10px 3px;
  padding-left: 4px;
}
.top-wrapper {
  .flexStyle(flex, space-between);
  margin-right: 20px;
  margin-bottom: 20px;
  .edit {
    color: #448bff;
    cursor: pointer;
  }
}
.top {
  .flexStyle(flex, flex-start);
  padding: 0 20px;
  h1 {
    font-size: 20px;
  }
  li {
    height: 20px;
    line-height: 20px;
    padding: 0 12px;
    margin-right: 10px;
    color: #448bff;
    background: #edf4ff;
    border-radius: 10px;
  }
}
.requirement-wrapper {
  .flexStyle(flex, space-between);
  margin-right: 20px;
  margin-bottom: 20px;
  color: #666666;
}
.requirement {
  .flexStyle(flex, flex-start);
  font-size: @medium;
  line-height: 20px;
  padding: 0 20px;
  li {
    margin-right: 20px;
    max-width: 500px;
    &.has-border {
      position: relative;
      &:after {
        content: '';
        width: 1px;
        height: 14px;
        background-color: #d3d3d3;
        position: absolute;
        right: -10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }
}
.el-divider {
  margin: 0;
  background-color: #f0f0f0;
}
.block {
  padding: 20px;
  &.has-border {
    position: relative;
    &:before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 20px;
      background-color: #448bff;
      border-radius: 1px;
    }
  }
  .title {
    line-height: 20px;
    font-weight: bold;
    color: #000;
    font-size: @medium;
  }
  .text {
    margin-top: 20px;
    color: #666666;
    font-size: @medium;
    white-space: pre-wrap;
  }
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
.yt-table {
  ::v-deep .el-loading-mask {
    z-index: auto;
  }
}
.stop {
  cursor: not-allowed !important;
  color: #999999 !important;
}
.bottom-drawer {
  .flexStyle(flex, flex-end, center);
  width: 100%;
  height: 60px;
  padding: 0 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
}
</style>
